import "./styles.scss";

import React from 'react';
import { Link } from 'gatsby';

const Reference = ({ children, link, photoSrc, altPhoto="" }) => {

    return (
        <section className="reference">
        <div className="container">
          <h2>Referencje dla tego projektu</h2>
          <div className="reference__content">
              {
                  photoSrc &&
                  <img
                    src={photoSrc}
                    alt={altPhoto}
                    className="img-fluid"
                />
              }

            { children }
          </div>
          <Link to={link} className="reference__button">Zobacz więcej</Link>
        </div>
      </section>
    )
}

export default Reference;