import React from "react";
import LazyLoad from "react-lazyload";

import {
  Layout,
  PortfolioNav,
  PortfolioHeader,
  Loader,
} from "../../components";
import Reference from "../../components/Reference";

import locales from "../../constants";

const Intertec = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Intertec Polska",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #ff7a21, #fc7114, #ff5a00, #e36009, #c75910 )",
        icons: "#ff5a00",
        navClass: "intertec__nav",
        ogImage: require("../../assets/img/portfolio/intertec_top_back.jpg"),
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/projects/intertec-polska/",
      // }}
    >
      <PortfolioHeader name="intertec" />

      <div className="intertec">
        <section className="intertec-section-1" id="info">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <h1>Intertec Polska</h1>
                <ul>
                  <li>Logo</li>
                  <li>Projekty opakowań</li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <p>
                  Firma Intertec Polska, właściciel popularnej, specjalizującej
                  się w akcesoriach rowerowych marki Profex, zgłosiła się do nas
                  z projektem odświeżenia brandu oraz zaprojektowania na nowo
                  ponad 150 opakowań.
                </p>
                <p>
                  Z uwagi na mnogość kategorii i produktów konieczne było
                  opracowanie koncepcji dla marki jako takiej oraz osobno dla
                  każdej z linii produktowych.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="intertec-section-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2>Projekt loga</h2>
                <p>
                  Proces projektowania rozpoczęliśmy od logo. To dotychczasowe
                  było zbyt mocno zakorzenione w początkach działalności firmy.
                </p>
              </div>
              <div className="col-12 col-md-6">
                <LazyLoad
                  once
                  height={140}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="img-fluid"
                      srcSet={require("../../assets/img/portfolio/intertec_logo_prev.avif")}
                      type="image/avif"
                    />
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/portfolio/intertec_logo_prev.jpg")}
                      alt="Poprzednia logo marki Profex"
                    />
                  </picture>
                </LazyLoad>
                <p className="text-center">Poprzednie logo</p>
              </div>
              <div className="col-12 col-md-6">
                <LazyLoad
                  once
                  height={140}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="img-fluid"
                      srcSet={require("../../assets/img/portfolio/intertec_logo_new.avif")}
                      type="image/avif"
                    />
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/portfolio/intertec_logo_new.jpg")}
                      alt="Nowe logo marki Profex"
                    />
                  </picture>
                </LazyLoad>
                <p className="text-center">Nowe logo</p>
              </div>

              <div className="col-12 col-md-5">
                <h2>Projekty opakowań</h2>
                <p>
                  Po zdefiniowaniu palety kolorów opracowaliśmy system
                  ikonograficzny kategoryzujący produkty oraz wypracowaliśmy
                  projekty master dla każdej z linii produktów. Łącznie z
                  zaprojektowaliśmy nowe szaty dla dokładnie 158 produktów -
                  wybrane z nich znajdują się poniżej.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="intertec-section-3">
          <div className="container-fluid">
            <div className="row justify-content-end">
              <div className="col-12 col-md-6">
                <LazyLoad
                  once
                  height={568}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="img-fluid"
                      srcSet={require("../../assets/img/portfolio/intertec_product_2.avif")}
                      type="image/avif"
                      alt="Uchwyt na bidon i lampy"
                    />
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/portfolio/intertec_product_2.png")}
                      alt="Uchwyt na bidon i lampy"
                    />
                  </picture>
                </LazyLoad>
                <p>Uchwyt na bidon / Lampa przednia / Lampa tylnia</p>
              </div>

              <div className="col-12 col-md-6">
                <LazyLoad
                  once
                  height={762}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="img-fluid"
                      srcSet={require("../../assets/img/portfolio/intertec_product_1.avif")}
                      type="image/avif"
                      alt="Pedały aluminiowe trekking slim"
                    />
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/portfolio/intertec_product_1.png")}
                      alt="Pedały aluminiowe trekking slim"
                    />
                  </picture>
                </LazyLoad>
                <p>Pedały aluminiowe trekking slim</p>
              </div>

              <div className="col-12 col-md-6">
                <LazyLoad
                  once
                  height={594}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="img-fluid"
                      srcSet={require("../../assets/img/portfolio/intertec_product_3.avif")}
                      type="image/avif"
                      alt="Owijki informacyjne na opony"
                    />
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/portfolio/intertec_product_3.png")}
                      alt="Owijki informacyjne na opony"
                    />
                  </picture>
                </LazyLoad>
                <p>Owijki informacyjne na opony</p>
              </div>
            </div>
          </div>
        </section>

        <section className="intertec-section-4">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12">
                <h2>Co było wyzwaniem na początku współpracy?</h2>
              </div>
              <div className="col-12 col-md-6">
                <h2>System rozliczeń agencja - klient</h2>
                <p>
                  Nasze początkowe rozmowy zakładały, że otrzymamy pliki
                  projektowe dotychczasowych opakowań i etykiet, aby sprawdzić
                  jakie dane możemy z nich wyciągnąć, a jakie będziemy musieli
                  przepisać. Było to istotne z punktu widzenia zakresu prac, a
                  to z kolei ściśle wiąże się w budżetem projektu. Finalnie
                  pracowaliśmy w oparciu o sukcesywnie dosyłane partie plików z
                  danymi kolejnych kategorii i produktów.
                </p>
                <LazyLoad
                  once
                  height={357}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="img-fluid"
                      srcSet={require("../../assets/img/portfolio/intertec_screen_1.avif")}
                      type="image/avif"
                      alt="System rozliczeń screen"
                    />
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/portfolio/intertec_screen_1.jpg")}
                      alt="System rozliczeń screen"
                    />
                  </picture>
                </LazyLoad>
              </div>
              <div className="col-12 col-md-6">
                <LazyLoad
                  once
                  height={728}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="img-fluid"
                      srcSet={require("../../assets/img/portfolio/intertec_clientProject.avif")}
                      type="image/avif"
                      alt="Projekt opakowania z wykrojnikiem"
                    />
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/portfolio/intertec_clientProject.jpg")}
                      alt="Projekt opakowania z wykrojnikiem"
                    />
                  </picture>
                </LazyLoad>

                <p>
                  Projekt opakowania wraz z wykrojnikiem dostarczony przez
                  klienta
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="intertec-section-5">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 order-1 align-self-start">
                <h2>Zarządzanie procesem</h2>
                <p>
                  Klient, przyzwyczajony do pracy w ten sposób, dostarczył nam
                  (świetnie co prawda) przygotowany plik excel z danymi
                  produktów, w oparciu o który mieliśmy realizować i akceptować
                  projekty oraz aktualizować dane kolejnych produktów do
                  zaprojektowania.
                </p>
                <p>
                  Zrealizowaliśmy już wiele bardzo złożonych projektów i choć to
                  nie ich wielkość jest kluczowa (bo każdy projekt realizujemy w
                  ten sposób) to w tym przypadku szczególnie istotne było to,
                  aby usystematyzować ogrom otrzymywanych danych oraz sprawnie
                  zarządzać kolejno projektowanymi produktami. Szczegółowe
                  zestawienie w excel było świetną bazą, ale nie mogło służyć do
                  prowadzenia projektu.
                </p>
              </div>
              <div className="col-12 col-md-6 order-2">
                <LazyLoad
                  once
                  height={510}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="d-none d-md-block"
                      srcSet={require("../../assets/img/portfolio/intertec_screen_2.avif")}
                      type="image/avif"
                      alt="Excel screen"
                    />
                    <img
                      className="d-none d-md-block"
                      src={require("../../assets/img/portfolio/intertec_screen_2.jpg")}
                      alt="Excel screen"
                    />
                  </picture>
                </LazyLoad>
              </div>
              <div className="col-12 col-md-7 order-4 order-md-3 d-flex flex-column align-items-center">
                <LazyLoad
                  once
                  height={934}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="d-none d-md-block"
                      srcSet={require("../../assets/img/portfolio/intertec_screen_3.avif")}
                      type="image/avif"
                      alt="ClickUp screens"
                    />
                    <img
                      className="d-none d-md-block"
                      src={require("../../assets/img/portfolio/intertec_screen_3.png")}
                      alt="ClickUp screens"
                    />
                  </picture>
                </LazyLoad>

                <LazyLoad
                  once
                  height={172}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="d-md-none"
                      srcSet={require("../../assets/img/portfolio/intertec_screen_3.1.avif")}
                      type="image/avif"
                      alt="ClickUp screen"
                    />
                    <img
                      className="d-md-none"
                      src={require("../../assets/img/portfolio/intertec_screen_3.1.jpg")}
                      alt="ClickUp screen"
                    />
                  </picture>
                </LazyLoad>
                <LazyLoad
                  once
                  height={172}
                  offset={100}
                  placeholder={<Loader />}
                >
                  <picture>
                    <source
                      className="d-md-none"
                      srcSet={require("../../assets/img/portfolio/intertec_screen_3.2.avif")}
                      type="image/avif"
                      alt="ClickUp screen"
                    />
                    <img
                      className="d-md-none"
                      src={require("../../assets/img/portfolio/intertec_screen_3.2.jpg")}
                      alt="ClickUp screen"
                    />
                  </picture>
                </LazyLoad>
              </div>
              <div className="col-12 col-md-5 order-3 order-md-4">
                <p>
                  Klient został zapoznany z naszym procesem projektowym
                  wykorzystującym oprogramowanie do zarządzania projektami. Po
                  krótkim wdrożeniu, dane kolejnych produktów do realizacji
                  dodawane były już w systemie.
                </p>
                <p>
                  Każdy produkt oraz jego warianty tworzyły przejrzystą
                  architekturę zadań do realizacji. Dodaliśmy pola z numerami
                  produktu, stworzyliśmy nowe, niestandardowe statusy (np.
                  oczekiwanie na wykrojnik czy pobranie pliku drukowego), które
                  ułatwiły Klientowi odnajdywanie się w procesie projektowym,
                  akceptację projektów oraz przekazywanie plików produkcyjnych
                  do drukarni.
                </p>
                <p>
                  Z uwagi na sukcesywnie dosyłane dane oraz nie do końca znany
                  zakres prac, projekt był realizowany w rozliczeniu godzinowym.
                  Klient otrzymał pełny dostęp nie tylko do dodawania zadań
                  (danych kolejnych produktów), ale również do czasu pracy
                  projektantów graficznych na poszczególnych zadaniach oraz
                  dashboardu do monitorowania postępu prac w czasie
                  rzeczywistym.
                </p>
                <p>
                  Taki sposób pracy, zapewnił sprawną realizację 158 projektów
                  (od koncepcji po przygotowanie pliku do druku). Przyjęty
                  sposób rozliczeń oraz sposób prowadzenia projektu pozwoliły na
                  wypracowanie modelu win win z którego Klient był finalnie
                  bardzo zadowolony.
                </p>
              </div>

              <div className="col-12 col-sm-6 col-xl-3 order-5">
                <div>
                  <LazyLoad
                    once
                    height={438}
                    offset={100}
                    placeholder={<Loader />}
                  >
                    <picture>
                      <source
                        className="img-fluid"
                        srcSet={require("../../assets/img/portfolio/intertec_screen_4.avif")}
                        type="image/avif"
                        alt="Status zadania screen"
                      />
                      <img
                        className="img-fluid"
                        src={require("../../assets/img/portfolio/intertec_screen_4.jpg")}
                        alt="Status zadania screen"
                      />
                    </picture>
                  </LazyLoad>
                  <p>Status zadania</p>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-xl-3 order-6">
                <div>
                  <LazyLoad
                    once
                    height={439}
                    offset={100}
                    placeholder={<Loader />}
                  >
                    <picture>
                      <source
                        className="img-fluid"
                        srcSet={require("../../assets/img/portfolio/intertec_screen_5.avif")}
                        type="image/avif"
                        alt="Delegowanie zadania screen"
                      />
                      <img
                        className="img-fluid"
                        src={require("../../assets/img/portfolio/intertec_screen_5.jpg")}
                        alt="Delegowanie zadania screen"
                      />
                    </picture>
                  </LazyLoad>
                  <p>Delegowanie zadania</p>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-xl-3 order-7">
                <div>
                  <LazyLoad
                    once
                    height={237}
                    offset={100}
                    placeholder={<Loader />}
                  >
                    <picture>
                      <source
                        className="img-fluid"
                        srcSet={require("../../assets/img/portfolio/intertec_screen_6.avif")}
                        type="image/avif"
                        alt="Priorytyzowanie zadania screen"
                      />
                      <img
                        className="img-fluid"
                        src={require("../../assets/img/portfolio/intertec_screen_6.jpg")}
                        alt="Priorytyzowanie zadania screen"
                      />
                    </picture>
                  </LazyLoad>
                  <p>
                    Prioretyzowanie <br /> zadania
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-xl-3 order-8">
                <div>
                  <LazyLoad
                    once
                    height={444}
                    offset={100}
                    placeholder={<Loader />}
                  >
                    <picture>
                      <source
                        className="img-fluid"
                        srcSet={require("../../assets/img/portfolio/intertec_screen_7.avif")}
                        type="image/avif"
                        alt="Opisy statusów zadania screen"
                      />
                      <img
                        className="img-fluid"
                        src={require("../../assets/img/portfolio/intertec_screen_7.jpg")}
                        alt="Opisy statusów zadania screen"
                      />
                    </picture>
                  </LazyLoad>
                  <p>Opisy statusów zadania dostosowane do projektu</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Reference link="/referencje/intertec-polska">
        <p>
          Adream sp. z o.o. wykonała dla nas analizę istniejących opakowań
          akcesoriów rowerowych oraz zaproponowała stworzenie nowej szaty
          graficznej i logo. Propozycja bardzo nam się spodobała i w pierwszym
          etapie zrealizowaliśmy 158 projektów w przeciągu niecałych 2 miesięcy.
        </p>
      </Reference>

      <LazyLoad once height={758} offset={100}>
        <PortfolioNav data={nav} locales={locales[lang]} />
      </LazyLoad>
    </Layout>
  );
};

export default Intertec;
